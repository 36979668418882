import { mapState } from "vuex";
import { getUserList, queryNotReadMessageNumber, sendMessageA, shopPotentialCustNum } from "./api";
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { getExport } from "./api";
import Util from "@/utils/utils";
import sendMessage from "@/views/userOperations/IntentionList/components/sendMessage";
import mySortBar from "./components/sortBar";
export default {
  components: {
    sendMessage,
    mySortBar
  },
  data() {
    return {
      sortList: [
        //0默认 1降 2升序
        { name: "录入时间排序", sortStatus: 1, sortName: "t.id desc" },
      ],
      nolistImg: require("@/assets/IntentionList/none.png"),
      pageLoadFlag: false,
      isFixed: false,
      leftJl:'225',
      visible:false,
      messageType:'',
      cherkItem:{},
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/IntentionList",
          name: "",
          title: "用户运营"
        }
      ],
      //列表数据
      listData: [],
      allCherkFlag: false,// 全选按钮标志
      batchCherkFlag: false,// 批量勾选标识
      isCherkZH: false,// 展示选中数据需要删除图标
      isShowMoreZH: false,// 综合更多选项展开收起
      isHaveVal: false,// 综合前边的删除图标是否展示
      PxList: [
        // {
        //   id: "",
        //   name: "综合排序"
        // },
        {
          id: "t.sort",
          name: "跟踪优先级"
        },
        {
          id: "t.id desc",
          name: "录入时间排序"
        }
      ],// 排序筛选列表
      filterLoadFlag: false,// 排序展示数据loading效果
      cherZhname: "录入时间排序",// 排序选中的名称
      isCherkYH: false,// 用户类型选中数据需要删除图标
      isShowMoreYH: false,// 用户类型展开收起更多
      isYHLXHaveVal: false,// 用户类型
      cherYHname: "", // 选中的用户类型名称
      filterLoadFlagyh: false,// 用户类型筛选loading
      userTypeList: [],// 筛选条件用户类型列表
      // 分页条件
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 非配置筛选条件
      filterData: {
        userTypeId: "",// 选中的用户类型id
        orderBy: "t.createdDate desc,t.id desc",// 排序选中的数据id
        // inCreatedDate: "",//录入时间
        // sortOrder:''
      },
      arg: {},
      headInfo: {},
      isLoading:false,
      pageElClickTime:'',
      cherkExoprtId:[],
      infoLength:0,
      specialActivityId:'',
      num: 0,
      total:0
    };
  },
  mounted() {
    window.addEventListener(
      "scroll",
      this.throttle(this.handleScroll, 10),
      true
    );
    this.getUserType();
    this.getHeadInfo();
    this.pageLoadFlag = true
    this.getNoticeLength()

  },
  methods: {
    //筛选排序
    sortClick(data) {
      const { sortName, sortStatus } = data;
      this.filterData = {
        ...this.filterData,
        // sortName: sortName,
        orderBy: sortStatus == 0 ? "" : sortStatus == 2 ? "t.createdDate asc,t.id asc" : "t.createdDate desc,t.id desc",
        page: 1
      };
      this.getList();

    },
    // 跳转页面
    gotoNotice(){
      let routeUrl = this.$router.resolve({
        path: '/center/notice',
        query: {
          filter: "type:" + 101007,
          onlyUserParam: true
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    // 获取反扑维度通知
    getNoticeLength() {
      queryNotReadMessageNumber({
        serviceCode:'queryNotReadMessageNumber',
        account:this.$store.state.user.userInfo.account.account
      }).then(res=>{
        this.infoLength = Number(res.data.total)
      })
    },
    //查看记录
    gotoCD(){
      let routeUrl = this.$router.resolve({
        path: "/touchRecord",
      });
      window.open(routeUrl.href, "_blank");
    },
    // 发短信
    Pushmessage(type,item){
      this.cherkItem = item
      this.messageType = type
      this.visible = true
    },

    confirmOk(data){
      if(this.messageType == 'message'){
        let dataA  = {
          list:[
            {
              "sendMessageDTO":
              {"sysCode":"S0008",//":"信天翁编码"
                "sendTo":[this.cherkItem.phone],//":"手机号"
                "contentSms":data.messageInfo
              },//":"发送内容"
              "custInfoId":this.$store.state.user.userInfo.customer.id,//":"商家id"
              "userId":this.cherkItem.id,//":"用户id"
              "memoId":""//":"模板id，如需使用模板则传这个值，模板内容会覆盖上面的发送内容"
            }
          ]
        }
        sendMessageA(dataA).then(res=>{
          if(res.data.code == 0) {
            this.$message.success('发送成功',3)
            this.visible = false
          } else {
            this.$message.warning(res.data.msg,3)
            this.visible = false
          }
        })
      } else{
        if(!data.userList || data.userList&&data.userList.length <= 0){
          this.$message.warning('请选择至少一个用户')
          return
        }
        let listaaa = []
        if(data.userList.length > 0) {
          data.userList.forEach(item=>{
            listaaa.push({
              "sendMessageDTO":
                {"sysCode":"S0008",//":"信天翁编码"
                  "sendTo":[item.phone],//":"手机号"
                  "contentSms":data.messageInfo
                },//":"发送内容"
              "custInfoId":this.$store.state.user.userInfo.customer.id,//":"商家id"
              "userId":item.id,//":"用户id"
              "memoId":""//":"模板id，如需使用模板则传这个值，模板内容会覆盖上面的发送内容"
            })
          })
        }
        let dataA = {
          list:listaaa
        }
        sendMessageA(dataA).then(res=>{
          if(res.data.code == 0) {
            this.$message.success('发送成功',3)
          } else {
            this.$message.warning(res.data.msg,3)
          }
        })
      }
    },
    exportClick() {
      let data = {
        ...this.arg,
        ...this.filterData,
        ids:(this.batchCherkFlag && this.cherkExoprtId.length > 0 && !this.allCherkFlag) ? this.cherkExoprtId.join(',') :''
      };
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "用户运营列表.xls");
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    // 用户类型筛选
    getUserType() {
      let data = {
        pid: 14927471377
      };
      focusonremarkplz(data).then(res => {
        this.userTypeList = res.data.list;
      });
    },

    // 跳转新增页面
    gotoAdd(type) {
      this.$router.push({
        path: "/IntendedUserEntry",
        query: {
          type: type
        }
      });
    },
    // 头部数据
    getHeadInfo() {
      let data = {
        inCreatedDate:this.arg.inCreatedDate?this.arg.inCreatedDate:''
      };
      shopPotentialCustNum(data).then(res => {
        if (res.data.code == 0) {
          if (res.data.list) {
            this.headInfo = res.data.list[0];
          }
        }
      });
    },
    // 分页
    handlePageChange(page, pageSize) {
      this.page.pageNum = page;
      this.page.pageSize = pageSize;
      this.getList();
    },

    // 获取列表
    getList() {
      this.pageLoadFlag = true;
      let data = {
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNum,
        ...this.arg,
        ...this.filterData,
        specialActivityId: this.specialActivityId
      };
      getUserList(data).then(res => {
        if (res.data.code == 0) {
          this.listData = res.data.list;
          this.total=res.data.totalRows;
          this.page.total = res.data.totalRows;
          if (this.listData.length > 0) {
            this.listData.forEach(item => {
              // 每个查看详情赋值默认隐藏
              item.display = false;
              // 多选框赋值 默认false
              item.ischerk = false;
              // 如果全选按钮点亮 每一页都要赋值选中状态
              if(this.allCherkFlag){
                item.ischerk = true;
              }
              // 如果选中和批量都是false 全都赋值false
              if(!this.allCherkFlag && !this.batchCherkFlag){
                item.ischerk = false;
              }
              // 如果选中  就往数组push
              if(item.ischerk) {
                this.cherkExoprtId.push(item.id)
              }
             //  如果选中数据大于0 走以下逻辑 去重
             if( this.cherkExoprtId.length > 0) {
               if(!this.allCherkFlag) {
                 this.batchCherkFlag=true
               }
               this.cherkExoprtId.forEach(idItem=>{
                 if(idItem == item.id) {
                   item.ischerk = true;
                 }
               })
             }
            });
          }
          this.getHeadInfo();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.listData = [];
          this.getHeadInfo();
          this.pageLoadFlag = false;
        }

      }).catch(error=>{
        console.log(error);
        this.pageLoadFlag = false;
      });
    },
    searchFun(arg) {
      this.arg = arg[0];
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      // if(this.num ==  0 ) {
      //   this.specialActivityId = this.$route.query.specialActivityId?this.$route.query.specialActivityId:''
      //   this.num += 1
      // } else  {
      //   this.specialActivityId = ''
      // }
      this.getList();

    },
    // 修改综合选项
    changeZH(item) {
      this.filterData.orderBy = item.id;
      this.cherZhname = item.name;
      if (this.filterData.orderBy) {
        this.isHaveVal = true;
      } else {
        this.isHaveVal = false;
      }
      this.isShowMoreZH = false;
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      if(this.isYHLXHaveVal &&this.isHaveVal ) {
        this.leftJl = 256
      } else {
        this.leftJl = 225
      }
      this.getList();
    },
    // 更多筛选点击
    formClick(isShow){
      if(isShow){
        this.isShowMoreYH = false;
        this.isShowMoreZH = false;
      }
    },
    //综合排序展开收起
    moreClick() {
      this.isShowMoreZH = !this.isShowMoreZH;
      if (this.isShowMoreZH) {
        this.isShowMoreYH = false;
      }
      //页面元素点击时间 -》 更多筛选监听后关闭
      this.pageElClickTime = new Date().getTime();
    },
    // 删除选中项目
    delectZH() {
      this.isShowMoreZH = false;
      this.isHaveVal = false;
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.filterData.orderBy = "t.createdDate desc,t.id desc";
      this.cherZhname = '录入时间排序'
      if(this.isYHLXHaveVal || this.isHaveVal ) {
        this.leftJl = 256
      } else {
        this.leftJl = 225
      }
      this.$forceUpdate();
      this.getList();
    },
    // 展开收起用户类型
    moreYHClick() {
      this.isShowMoreYH = !this.isShowMoreYH;
      if (this.isShowMoreYH) {
        this.isShowMoreZH = false;
      }
      //页面元素点击时间 -》 更多筛选监听后关闭
      this.pageElClickTime = new Date().getTime();
    },
    // 修改用户选项
    changeYH(item) {
      this.filterData.userTypeId = item.code;
      this.cherYHname = item.name;
      if (this.filterData.userTypeId) {
        this.isYHLXHaveVal = true;
      } else {
        this.isYHLXHaveVal = false;
      }
      this.isShowMoreYH = false;
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      if(this.isYHLXHaveVal  || this.isHaveVal ) {
        this.leftJl = 256
      } else {
        this.leftJl = 225
      }
      this.getList();
    },
    // 删删除用户类型选项
    delectYHLX() {
      this.filterData.userTypeId = "";
      this.isShowMoreYH = false;
      this.isYHLXHaveVal = false;
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      if(this.isYHLXHaveVal &&this.isHaveVal ) {
        this.leftJl = 256
      } else {
        this.leftJl = 225
      }
      this.getList();
    },
    // 底部固定部分代码
    //throttle：
    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    },
    // 筛选条件===》修改录入时间
    // onChangeInputTime(date, dateString) {
    //   // this.filterData.inCreatedDate = dateString && dateString[0] ? dateString[0] + "_" + dateString[1] : "";
    //   this.page.pageNum = 1;
    //   this.page.pageSize = 10;
    //   this.getList();
    // },
    // 查看详情
    gotoDetail(item) {
      let routeUrl = this.$router.resolve({
        path: "/IntentionDetail",
        query: {
          id: item.id
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    gotoBuy(item,type) {
      // let routeUrl = this.$router.resolve({
      //   path: "/installationRepairReporting/workOrderSubmission",
      //   query: {
      //     item: JSON.stringify(item),
      //     type:type,
      //     form:'list'
      //   }
      // });
      // window.open(routeUrl.href, "_blank");
      this.$router.push({
          path: "/installationRepairReporting/workOrderSubmission",
          query: {
            item: JSON.stringify(item),
            type:type,
            form:'list'
          }
      })
    },
    // 多选框勾选
    changeItemCherk(e, item) {
      item.ischerk = e.target.checked;
      if(item.ischerk){
        this.cherkExoprtId.push(item.id)
      } else {
        for (let i = 0; i < this.cherkExoprtId.length; i++) {
          if (this.cherkExoprtId[i] == item.id) {
            this.cherkExoprtId.splice(i, 1);
          }
        }
      }
      this.$forceUpdate();
      let num = 0;
      this.listData.forEach(item => {
        if (item.ischerk == false) {
          num += 1;
        }
      });

      if (this.cherkExoprtId.length <= 0) {
        this.allCherkFlag = false;
        this.batchCherkFlag = false;
        this.$forceUpdate();
      } else if (this.cherkExoprtId.length == this.page.total) {
        this.allCherkFlag = true;
        this.batchCherkFlag = false;
        this.$forceUpdate();

      } else if (this.cherkExoprtId.length < this.page.total) {
        this.batchCherkFlag = true;
        this.allCherkFlag = false;
        this.$forceUpdate();
      }

    },
    // 鼠标移入 展示查看详情
    mousemove(item) {
      item.display = true;
      this.$forceUpdate();
    },
    // 鼠标移出隐藏查看详情
    leave(item) {
      item.display = false;
      this.$forceUpdate();
    },
    //  全选
    allCherk(e) {
      // 全选按钮
      this.allCherkFlag = e.target.check;
      if (e.target.checked) {
        this.cherkExoprtId = []
        this.batchCherkFlag = false
        this.listData.forEach(item => {
          item.ischerk = true;
          this.cherkExoprtId.push(item.id)
        });
      } else {
        this.listData.forEach(item => {
          item.ischerk = false;
          this.cherkExoprtId = []
        });
      }
      this.$forceUpdate();
    },
    // 批量
    batchCherk(e) {
      if (!e.target.checked) {
        this.cherkExoprtId = []
        this.listData.forEach(item => {
          item.ischerk = false
        })
      } else {
        this.allCherkFlag = false
      }
    }
  }

};
